import FooterStanford from "../components/FooterStanford";
import HeaderStanford from "../components/HeaderStanford";

//import { projectBasename } from "../utils/constant_values";

const NoPage = () => {
  return (
    <div>
      <HeaderStanford />
      {/* <SiteGridExample /> */}
      {/* <span className="text-gray-700">Formik Land</span> */}

      {/* https://dev.to/hi_iam_chris/react-forms-formik-and-yup-intro-154g */}
      {/* https://stackoverflow.com/questions/56292392/i-cant-send-post-request-from-react-form-to-flask-server */}
      {/* https://sanjeevan.co.uk/blog/flask-marshmallow-and-formik/ */}
      <div className="cc flex flex-col md:flex-row rs-py-2 overflow-x-hidden">
        {/* <div className="mx-auto w-full lg:w-3/4"> */}
        <div className="mx-auto w-full">
          <article id="text__paragraphs" className="rs-mb-5">
            <div>
              There is nothing found here. Please go back to the{" "}
              {/* <a href={projectBasename}>main page</a> */}
              <a href="/">main page</a>
            </div>
          </article>
        </div>
      </div>
      <FooterStanford />
    </div>
  );
};

export default NoPage;
