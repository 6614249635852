import React, { useState, useRef, useEffect } from "react";
import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";
import { ClockIcon } from "@heroicons/react/24/outline";

import {
  convertEpochToPST,
  currentRoundName,
  getApprovalValues,
  programDifferentiatorNoAdvisor,
  programAcronymFromDataFullProgram,
  toPacificTimeZone,
} from "../utils/auxiliary_functions";

import { storage, ref, getDownloadURL } from "../../firebase.config.js";

const AccordionComponent: React.FC<{
  title: string;
  children: any;
  toggleSelectedCurrent: boolean;
  color: string;
}> = ({ title, children, toggleSelectedCurrent, color }) => {
  const [selected, setSelected] = useState<boolean>(toggleSelectedCurrent);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.maxHeight = selected
        ? `${containerRef.current.scrollHeight}px`
        : "0px";
    }
  }, [selected]);

  const toggleSelected = () => {
    setSelected(!selected);
  };

  return (
    <div className="bg-white max-w-full mx-auto border border-gray-200">
      <ul className="shadow-box">
        <li className="relative border-b border-gray-200 list-none">
          <button
            type="button"
            className="w-full px-6 py-6 text-left"
            onClick={toggleSelected}
          >
            <div className="flex items-center justify-between py-14">
              <span className="flex items-center">
                <span
                  className="colored-status-box"
                  style={{
                    backgroundColor: color,
                  }}
                ></span>
                {title}
              </span>

              <svg
                className={`w-20 h-20 mr-15 text-gray-500 transition-transform ${
                  selected ? "transform rotate-180" : ""
                }`}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </button>
          <div
            className="relative overflow-hidden transition-all max-h-0 duration-700"
            ref={containerRef}
          >
            <div className="px-6 pb-6">{children}</div>
          </div>
        </li>
      </ul>
    </div>
  );
};

const CommentDisplayOfRow = ({ relevantSubmission }: any) => {
  if (!relevantSubmission?.row_comments?.length) return null;

  return (
    <div className="mb-20">
      <ul className="proposal-status-comments">
        {relevantSubmission.row_comments
          .sort((a: any, b: any) => a.modified_epoch - b.modified_epoch)
          .map((comment: any, index: number) => (
            <li key={index} className="mb-10 list-none break-words">
              <span className="font-semibold">
                <ChatBubbleBottomCenterTextIcon className="float-left h-20 w-20 text-slate-500 stroke-[2px] mr-5 mt-5" />
                {comment.created_by_name} on {}
                {convertEpochToPST(comment.modified_epoch)}
              </span>
              <div>{comment.title}</div>
            </li>
          ))}
      </ul>
    </div>
  );
};

const getStatusMessage = (
  values: any,
  programAcronym: string,
  submissionRoundNumber: number,
) => {
  // TODO: sync these colors with the current standing colors make sure the same
  //bg-red-500: #EF4444
  //bg-yellow-500: #F59E0B
  //bg-green-500: #10B981
  const color = {
    red: "#FFA1A5",
    yellow: "#FFEB93",
    green: "#7DEFB1",
  };

  if (values.length === 0) {
    return {
      message: "Submitted Awaiting Approval",
      color: color["yellow"],
    };
  } else if (values.includes("Revise & Resubmit")) {
    return { message: "Revise And Resubmit", color: color["red"] };
  } else if (
    (values.length === 3 ||
      (programDifferentiatorNoAdvisor(programAcronym, submissionRoundNumber) &&
        values.length === 2)) &&
    values.every((item: string) => item === "Approved")
  ) {
    return { message: "Fully Approved", color: color["green"] };
  } else {
    return {
      message: "Partially Approved Awaiting Approval",
      color: color["yellow"],
    };
  }
};

const SubmissionComponent: React.FC<{
  submission: any;
  programAcronym: string;
  isMostRecent: boolean;
}> = ({ submission, programAcronym, isMostRecent }) => {
  const localCurrentRoundName = currentRoundName(submission, programAcronym);
  const localMostRecentSubmissionCreated = toPacificTimeZone(
    submission.created,
    "MMMM d, yyyy h:mmaaa",
  );

  const approvalValues = getApprovalValues(submission);
  const submissionRoundNumber = parseInt(submission.submission_round, 10);
  const statusMessageItems = getStatusMessage(
    approvalValues,
    programAcronym,
    submissionRoundNumber,
  );
  //const localStatusMessage = statusMessageItems.message;
  const localStatusColor = statusMessageItems.color;
  // const localTitle = `Round: ${localCurrentRoundName} / Submitted: ${localMostRecentSubmissionCreated} / Status: ${localStatusMessage}`;
  const localTitle = `Submitted on ${localMostRecentSubmissionCreated}`;
  const sunet_email = submission.sunet_email;

  const [attachmentUrls, setAttachmentUrls] = useState<string[]>([]);

  useEffect(() => {
    const fetchUrls = async () => {
      const urls = await Promise.all(
        submission.attachment_url_list.map(async (filename: string) => {
          const validFirestorePdfPath = `${sunet_email}/${filename}`;
          try {
            const url = await getDownloadURL(
              ref(storage, validFirestorePdfPath),
            );
            return url;
          } catch (error) {
            console.error("Error fetching URL:", error);
            return ""; // Return an empty string or handle the error as needed
          }
        }),
      );
      setAttachmentUrls(urls);
    };

    fetchUrls();
  }, [submission.attachment_url_list, sunet_email]);

  return (
    <AccordionComponent
      title={localTitle}
      toggleSelectedCurrent={isMostRecent}
      color={localStatusColor}
    >
      <div
        className={`flex flex-col lg:flex-row pr-15 ${
          isMostRecent ? "mb-20" : ""
        }`}
      >
        <div className="w-full lg:w-1/2">
          <table className="progress-table">
            <tbody>
              <tr>
                <th>Submission Round</th>
                <td>{localCurrentRoundName}</td>
              </tr>
              <tr>
                <th>Program Officer</th>
                <td>
                  {submission.officer_email_approval || (
                    <ClockIcon className="h-20 w-20 text-slate-500 stroke-[2px]" />
                  )}
                </td>
              </tr>
              <tr>
                <th>Program Director</th>
                <td>
                  {submission.director_email_approval || (
                    <ClockIcon className="h-20 w-20 text-slate-500 stroke-[2px]" />
                  )}
                </td>
              </tr>
              {!programDifferentiatorNoAdvisor(
                programAcronym,
                submissionRoundNumber,
              ) && (
                <tr>
                  <th>Faculty Advisor</th>
                  <td>
                    {submission.advisor_email_approval || (
                      <ClockIcon className="h-20 w-20 text-slate-500 stroke-[2px]" />
                    )}
                  </td>
                </tr>
              )}
              <tr>
                <th>Attached Proposal</th>
                <td>
                  {attachmentUrls.map((url: string, innerIndex: number) => {
                    return (
                      <React.Fragment key={innerIndex}>
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          {innerIndex === 0
                            ? "Download PDF"
                            : `Attachment #${innerIndex}`}
                        </a>
                        {innerIndex !==
                          submission.attachment_url_list.length - 1 && ", "}
                      </React.Fragment>
                    );
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="w-full mt-20 lg:w-1/2 lg:mt-0">
          <CommentDisplayOfRow relevantSubmission={submission} />
        </div>
      </div>
    </AccordionComponent>
  );
};

export const SmartsheetPresentStatus: React.FC<{
  smartsheetResponse: any;
  userdataPersonal: any;
}> = ({ smartsheetResponse, userdataPersonal }) => {
  const smartsheetResponseReverse = smartsheetResponse.slice();

  const [mostRecentSubmission, ...previousSubmissions] =
    smartsheetResponseReverse;
  const programAcronym = programAcronymFromDataFullProgram(userdataPersonal);

  return (
    <>
      <h2 className="text-4xl mt-20">Current Submission</h2>
      <SubmissionComponent
        submission={mostRecentSubmission}
        programAcronym={programAcronym}
        isMostRecent={true}
      />

      {previousSubmissions.length > 0 && (
        <>
          <h2 className="text-4xl mt-20">Previous Submissions</h2>
          {previousSubmissions.map((row: any, index: number) => (
            <div key={index} className="mb-40">
              <SubmissionComponent
                submission={row}
                programAcronym={programAcronym}
                isMostRecent={false}
              />
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default SmartsheetPresentStatus;
