import { functions } from "../../firebase.config.js";
import { httpsCallable } from "firebase/functions";

import { some } from "lodash";
import { toast } from "react-toastify";
import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router";
//import { pdfjs, Document, Page } from "react-pdf";
//import "react-pdf/dist/esm/Page/TextLayer.css";
//pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//  "pdfjs-dist/build/pdf.worker.min.mjs",
//  import.meta.url,
//).toString();

import {
  //generateNumberRange,
  //getNumberOfQuarters,
  generateYearQuarterDataList,
  getPriorYearRanges,
  getQuartersSetup,
  getStudentFormSubmissionAndSetSubmittedStatus,
} from "../utils/auxiliary_functions";

//import { handleProcessFiles } from "../utils/auxiliary_functions";

//import { FilePond, registerPlugin } from "react-filepond";
//import "filepond/dist/filepond.min.css";
//import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
//import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
//import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
//import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
//import FilePondPluginImagePreview from "filepond-plugin-image-preview";
//registerPlugin(
//  FilePondPluginImageExifOrientation,
//  FilePondPluginFileValidateSize,
//  FilePondPluginFileValidateType,
//  FilePondPluginImagePreview,
//);

//const FileUploadDropZone: React.FC<{
//  sunetEmailAddress: string;
//  setFiles: any;
//  setUploading: any;
//  files: any;
//  uploading: any;
//  onClose: any;
//  smartsheetResponse: any;
//  setAttachmentProcessing: any;
//  setShouldPageRefreshContent: any;
//}> = ({
//  sunetEmailAddress,
//  setFiles,
//  setUploading,
//  files,
//  uploading,
//  onClose,
//  smartsheetResponse,
//  setAttachmentProcessing,
//  setShouldPageRefreshContent,
//}) => {
//  return (
//    <div>
//      <FilePond
//        files={files}
//        allowMultiple={true}
//        maxFiles={5}
//        onupdatefiles={(fileItems) => {
//          setFiles(fileItems.map((fileItem) => fileItem.file) as any);
//        }}
//        name="files"
//        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
//        credits={false}
//        acceptedFileTypes={[
//          "text/plain",
//          "application/pdf",
//          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
//          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//          "text/csv",
//        ]}
//      />
//      <div className="flex justify-end">
//        <button type="button" onClick={onClose} className="button mr-6 my-16">
//          Cancel
//        </button>
//        <button
//          onClick={async () => {
//            try {
//              setAttachmentProcessing(true);
//              await handleProcessFiles(
//                files,
//                sunetEmailAddress,
//                setUploading,
//                onClose,
//                smartsheetResponse,
//                setAttachmentProcessing,
//                setShouldPageRefreshContent,
//              );
//            } catch (error) {
//              setAttachmentProcessing(false); // get rid of the spinner, it failed to go through
//              console.error("Error occurred while submitting:", error);
//              //} finally {
//              //setAttachmentProcessing(false); // the spinner is automatically removed by the page reload, and the spinner starts again to load the page
//            }
//          }}
//          disabled={uploading}
//          type="button"
//          className="button my-16 mr-6"
//        >
//          Upload
//        </button>
//      </div>
//    </div>
//  );
//};

const unique_schedule = async (
  student_email: string,
  selectedQuarterYear: any,
) => {
  try {
    let data: {
      student_email?: string;
      selectedQuarterYear?: any;
    } = {};

    data["student_email"] = student_email;
    data["selectedQuarterYear"] = selectedQuarterYear;
    console.log("data in unique schedule");
    console.log(data);

    const fetchData = httpsCallable(
      functions,
      "firebase_unique_schedule_firestore2",
    );
    const result = await fetchData(data); // Add 'await' here to wait for the result
    return result.data; // Return the data from the fetched result
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const complete_proposal_send_to_smartsheet = async (
  values: any,
  pdfFileName: any,
  setFormIntakeVisible: any,
  setLoadingSmartsheet: any,
) => {
  try {
    const extraVariables = {
      custom_filename: pdfFileName,
    };
    const senddata = { ...values, ...extraVariables };
    setLoadingSmartsheet(true);

    const fetchData = httpsCallable(
      functions,
      "firebase_complete_proposal_send_to_smartsheet_firestore",
    );
    const result = await fetchData(senddata);
    const data = result.data as any;
    const fetchedDataStudent = data[0];
    if (data[1] !== 200) {
      const error = fetchedDataStudent.error;
      throw new Error(
        `Failed to send data to the Firebase python backend. ${error}`,
      );
    }
    setFormIntakeVisible(false);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

//export const AttachmentModal: React.FC<{
//  student_email: string;
//  isOpen: boolean;
//  onClose: () => void;
//  setFiles: any;
//  setUploading: any;
//  files: any;
//  uploading: any;
//  smartsheetResponse: any;
//  setAttachmentProcessing: any;
//  setShouldPageRefreshContent: any;
//}> = ({
//  student_email,
//  isOpen,
//  onClose,
//  setFiles,
//  setUploading,
//  files,
//  uploading,
//  smartsheetResponse,
//  setAttachmentProcessing,
//  setShouldPageRefreshContent,
//}) => {
//  return (
//    <div
//      className={`fixed inset-0 z-50 ${
//        isOpen ? "" : "hidden"
//      } overflow-auto bg-gray-800 bg-opacity-75`}
//    >
//      <div className="bg-white pr-16 pl-16 pt-16 mx-auto max-w-2xl rounded shadow-lg mt-20">
//        <div className="mb-4">
//          <h1 className="text-3xl">Attach Files</h1>
//        </div>
//        <div className="mb-4 mt-10">
//          <p>PDF and MS Word format only.</p>
//          <FileUploadDropZone
//            sunetEmailAddress={student_email}
//            setFiles={setFiles}
//            setUploading={setUploading}
//            files={files}
//            uploading={uploading}
//            onClose={onClose}
//            smartsheetResponse={smartsheetResponse}
//            setAttachmentProcessing={setAttachmentProcessing}
//            setShouldPageRefreshContent={setShouldPageRefreshContent}
//          />
//        </div>
//      </div>
//    </div>
//  );
//};

export const UniqueScheduleModal: React.FC<{
  student_email: string;
  isOpen: boolean;
  onClose: () => void;
  program: string;
  constantValues: any;
  setShouldPageRefreshContent: any;
  year_quarter_setup: any;
  year_quarter_setup_original: any;
}> = ({
  student_email,
  isOpen,
  onClose,
  program,
  constantValues,
  setShouldPageRefreshContent,
  year_quarter_setup,
  year_quarter_setup_original,
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const selectRef = useRef<HTMLSelectElement>(null);
  useEffect(() => {
    if (isOpen && selectRef.current) {
      setTimeout(() => {
        selectRef.current?.blur();
      }, 0);
    }
  }, [isOpen]);

  const [selectedQuarterYear, setSelectedQuarterYear] = useState<any>(null);

  if (year_quarter_setup_original) {
    const handleQuarterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue =
        e.target.value === "" ? null : JSON.parse(e.target.value);
      setSelectedQuarterYear(selectedValue);
    };

    const beginningYearOriginal = year_quarter_setup_original[0]["year"];
    const defaultYear = constantValues.defaultYear;
    const yearRangeList = getPriorYearRanges(
      beginningYearOriginal,
      defaultYear,
      program,
    );
    const quarterSetup = getQuartersSetup(program);
    const yearQuarterDataList = generateYearQuarterDataList(
      yearRangeList,
      quarterSetup,
      program,
    );

    return (
      <div
        className={`fixed inset-0 z-50 ${
          isOpen ? "" : "hidden"
        } overflow-auto bg-gray-800 bg-opacity-75 flex items-center justify-center`}
      >
        <div className="bg-white pr-16 pl-16 pt-16 mx-auto max-w-2xl rounded shadow-lg">
          <div className="mb-4">
            <h1 className="text-3xl">
              Unique Schedule - Change Proposal Layout
            </h1>
          </div>

          <div className="mb-4">
            <label
              htmlFor="quarter"
              className="block text-2xl font-large text-gray-600 my-12"
            >
              Add a Quarter
            </label>

            <select
              id="quarter"
              name="quarter"
              className="select select-always rounded-none text-xl w-full"
              onChange={handleQuarterChange}
              value={
                selectedQuarterYear ? JSON.stringify(selectedQuarterYear) : ""
              }
              tabIndex={0}
              ref={selectRef}
            >
              <option value="" disabled>
                Select a quarter year combination
              </option>
              {yearQuarterDataList.map((data: any) => (
                <option
                  key={`${data.quarter}_${data.year}`}
                  value={JSON.stringify(data)}
                >
                  {data.quarter} {data.year}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4 mt-20">
            <label
              htmlFor="num-quarters"
              className="block text-2xl font-bold text-red-600"
            >
              Caution: This will create a fresh clean slate proposal -- i.e.
              your current items filled out will be removed and reset.
            </label>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="button mr-6 my-16"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={async () => {
                console.log("Selected Quarter Year:", selectedQuarterYear);
                if (!selectedQuarterYear) {
                  toast.error(
                    "Please select a quarter, or Cancel and close the Unique Schedule option",
                  );
                  return;
                }

                const foundObject = some(
                  year_quarter_setup,
                  selectedQuarterYear,
                );
                //console.log(foundObject); the item was already added...
                if (!foundObject) {
                  try {
                    await unique_schedule(student_email, selectedQuarterYear);
                    onClose();
                    //  //window.location.reload();
                    setShouldPageRefreshContent(true);
                    setTimeout(() => setShouldPageRefreshContent(false), 500); // shortly thereafter provide a reset
                  } catch (error) {
                    console.error("Error occurred while submitting:", error);
                  }
                } else {
                  toast.error(
                    "You already added that quarter to your unique schedule",
                  );
                }
              }}
              className="button my-16"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export const PdfModal: React.FC<{
  values: any;
  isOpen: boolean;
  onClose: () => void;
  urlPdfFileStorage: string;
  pdfFileName: string;
  setFormIntakeVisible: any;
  setLoadingSmartsheet: any;
}> = ({
  values,
  isOpen,
  onClose,
  urlPdfFileStorage,
  pdfFileName,
  setFormIntakeVisible,
  setLoadingSmartsheet,
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  const navigate = useNavigate();

  //const [numPages, setNumPages] = useState(null);
  //const [pageNumber, setPageNumber] = useState(1);
  const [isSubmittingToSmartsheet, setIsSubmittingToSmartsheet] =
    useState(false);

  //const onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
  //  setNumPages(numPages);
  //  setPageNumber(1);
  //};

  return (
    <div
      className={`fixed inset-0 z-50 ${
        isOpen ? "" : "hidden"
      } overflow-auto bg-gray-800 bg-opacity-75 flex items-center justify-center`}
    >
      <div className="bg-white pr-16 pl-16 pt-30 mx-auto max-w-7xl rounded shadow-lg relative top-[-10%]">
        <div className="flex flex-col items-center">
          <div className="mb-4">
            <h1 className="text-4xl">
              PDF Preview before Submitting to Program Officer
            </h1>
          </div>
          <div className="mb-12">
            <div>
              <a href={urlPdfFileStorage} target="_blank">
                Download PDF (optional)
              </a>
            </div>
          </div>

          {/*<div>
            {urlPdfFileStorage ? (
              <>
                <Document
                  file={urlPdfFileStorage}
                  renderMode="canvas"
                  onLoadSuccess={onDocumentLoadSuccess}
                  className="border border-gray-300"
                >
                  <Page width={600} pageNumber={pageNumber} />
                </Document>
                <div className="flex flex-col items-center">
                  <div>
                    <button
                      disabled={pageNumber <= 1}
                      onClick={() => setPageNumber(pageNumber - 1)}
                      type="button"
                      aria-label="Previous page"
                      className="text-6xl pr-8"
                    >
                      ‹
                    </button>
                    <span>
                      {pageNumber} of {numPages}
                    </span>
                    <button
                      disabled={pageNumber >= (numPages ?? 1)}
                      onClick={() => setPageNumber(pageNumber + 1)}
                      type="button"
                      aria-label="Next page"
                      className="text-6xl pl-8"
                    >
                      ›
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <div>Loading PDF...</div>
            )}
          </div> */}
        </div>

        <div className="flex justify-end">
          <button type="button" onClick={onClose} className="button mr-6 my-16">
            Cancel
          </button>
          <button
            type="button"
            disabled={isSubmittingToSmartsheet}
            onClick={async () => {
              setIsSubmittingToSmartsheet(true);
              const id_form_submission = values.id_form_submission;
              const student_email = values.student_email;
              try {
                getStudentFormSubmissionAndSetSubmittedStatus(
                  student_email,
                  id_form_submission,
                  true,
                );
                await complete_proposal_send_to_smartsheet(
                  values,
                  pdfFileName,
                  setFormIntakeVisible,
                  setLoadingSmartsheet,
                );
                onClose();
                navigate("/status", { replace: true });
              } catch (error) {
                await getStudentFormSubmissionAndSetSubmittedStatus(
                  student_email,
                  id_form_submission,
                  false,
                );
                console.error("Error occurred while submitting:", error);
              } finally {
                setIsSubmittingToSmartsheet(false);
              }
            }}
            className={`button my-16 ${
              isSubmittingToSmartsheet ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {isSubmittingToSmartsheet ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};
