import { Field } from "formik";
import { GenericError } from "../utils/errors";

const PlanAPlanBCheckBoxes: React.FC<{ errors: any; submitCount: number }> = ({
  errors,
  submitCount,
}) => {
  return (
    <>
      <label className="flex items-center space-x-2 my-4">
        <Field
          type="radio"
          name="plan"
          id="planA"
          value="a"
          className="input transform scale-150"
        />
        <span>
          {" "}
          &nbsp; Plan A) 11-18 units Autumn/Winter/Spring and exactly 3 units in
          Summer
        </span>
      </label>
      <label className="flex items-center space-x-2">
        <Field
          type="radio"
          name="plan"
          id="planB"
          value="b"
          className="input transform scale-150"
        />
        <span>
          {" "}
          &nbsp; Plan B) 11-18 units Autumn/Winter, 8-10 Spring, and exactly 6
          units in Summer
        </span>
      </label>
      <div className="mt-4">
        <GenericError
          errors={errors}
          submitCount={submitCount}
          fieldName="plan"
        />
      </div>
    </>
  );
};

export default PlanAPlanBCheckBoxes;
