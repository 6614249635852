import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

import { Field } from "formik";

import { handbookUrlSuffixDictionary } from "../utils/constant_values";

import {
  //unitsLetterGraded,
  //unitsAtOrAbove200,
  //unitsForEDUC,
  //unitsForProgramType,
  //RulesObject,
  initializeMeta,
} from "../utils/auxiliary_functions";

const GridWithCheckBoxes: React.FC<{ values: any }> = ({ values }) => {
  //const programList = initMeta(program);
  //console.log(programList);
  const program = values.program;
  if (!values.year_quarter_setup) {
    return <></>;
  }
  const programObject = initializeMeta(values);
  //const checkboxes = [...Array(programList.length).keys()];
  const checkboxes = [];

  for (let i = 0; i < Object.keys(programObject).length; i++) {
    checkboxes.push(i);
  }

  const handbookUrlSuffix = handbookUrlSuffixDictionary[program];
  const handbookUrl = `https://ed.stanford.edu/academics/${handbookUrlSuffix}`;
  return (
    <>
      {/* <div className="divider my-10"></div> */}
      <div className="my-10">
        Before submitting for approval, please review your coursework to ensure
        all of your{" "}
        <a
          href={handbookUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: "inline-block", alignItems: "center" }}
        >
          program requirements
          <ArrowTopRightOnSquareIcon className="h-16 w-16 pl-2 inline" />
        </a>{" "}
        are met from the Master's Handbook.
      </div>
      <div className="my-4">
        {checkboxes.map((_, index) => (
          <li key={index} className="flex items-center">
            <Field
              type="checkbox"
              name={`checkboxes.checkbox${index + 1}.checked`}
              id={`checkboxes.checkbox${index + 1}.checked`}
              className="transform scale-150 hidden"
              disabled
            />
            {/* Achieved/Pending Column with a fixed width */}
            <Field name={`checkboxes.checkbox${index + 1}.checked`}>
              {({ field }: any) => (
                <div
                  className={
                    field.value
                      ? "text-emerald-500 w-100"
                      : "text-yellow-500 w-100"
                  }
                >
                  {field.value ? "Achieved" : "Pending"}
                </div>
              )}
            </Field>

            {/* Label Column with flex-grow to take the rest of the space */}
            <label
              htmlFor={`checkboxes.checkbox${index + 1}`}
              className="text-gray-800 pl-4 flex-grow"
            >
              {programObject[`checkbox${index + 1}`]["label"]}
            </label>
          </li>
        ))}
      </div>
    </>
  );
};

export default GridWithCheckBoxes;

// making the rules
// rules to make the checkmark text
//
// to be applied later in the calculations section
//
// have as input the program name
//

// common rules
//const TRule1 = "All courses at or above the 100 level";
// "All courses at or above the 100 level"
//
// "15+ Letter graded units"
// "65+ Letter graded units"
// "17+ Letter graded units"
// "16+ Letter graded units"
// "15+ Letter graded units"
// "27+ Letter graded units"
//
// "23+ units at or above the 200 level"
// "48+ units at or above the 200 level"
// "25+ units at or above the 200 level"
// "24+ units at or above the 200 level"
// "18+ units at or above the 200 level"
//
//const Rule2 = "8-18 units each quarter";
//const TRule2 = "8-18 units Aut/Win/Spr";
//const TRule3 = "11-18 units each quarter";
//const TRule4 = "8-18 units Aut/Win/Spr; 6 Smr";
//const TRule5 = "11/11/8/6 or 11/11/11/3";
// "8-18 units each quarter"
// "11-18 units each quarter"
// "8-18 units Aut/Win/Spr; 6 Smr"
// "11/11/8/6 or 11/11/11/3"
//
// "27+ EDUC units"
// "30+ EDUC units"
//
// "35+ units toward MA"
// "45+ units toward MA"
// "48+ units toward MA"
// "51+ units toward MS"
// "95+ units toward MA/MPP"
//
//const TRule6 = "EDUC 393";
//const TRule7 = "10 cross-listed units max";
//const TRule8 = "4 independent study units max";
//const TRule0 = "Course Requirements";
//const TRuleGPA = "3.0 GPA";
// "EDUC 393"
// "10 cross-listed units max"
// "4 independent study units max"

// LEGACY
// LEGACY
// LEGACY
// LEGACY
//const initMeta = (program: string) => {
//  let meta = [];
//  if (program === "pols") {
//    meta[0] = "All courses at or above the 100 level";
//    meta[1] = "15+ Letter graded units"; //
//    meta[2] = "23+ units at or above the 200 level";
//    meta[3] = "11-18 units each quarter";
//    meta[4] = "27+ EDUC units";
//    meta[5] = "45+ units toward MA";
//    meta[6] = "Course Requirements"; // how calculate?
//  } else if (program === "ldt") {
//    meta[0] = "All courses at or above the 100 level";
//    meta[1] = "15+ Letter graded units";
//    meta[2] = "23+ units at or above the 200 level";
//    meta[3] = "8-18 units Aut/Win/Spr; 6 Smr";
//    meta[4] = "30+ EDUC units";
//    meta[5] = "45+ units toward MS";
//    meta[6] = "Course Requirements"; // how calculate?
//  } else if (program === "hcp") {
//    meta[0] = "All courses at or above the 100 level";
//    meta[1] = "15+ Letter graded units";
//    meta[2] = "23+ units at or above the 200 level";
//    meta[3] = "30+ EDUC units";
//    meta[4] = "45+ units toward MS";
//    meta[5] = "Course Requirements"; // how calculate?
//  } else if (program === "mpp") {
//    meta[0] = "All courses at or above the 100 level";
//    meta[1] = "65+ Letter graded units";
//    meta[2] = "48+ units at or above the 200 level";
//    meta[3] = "8-18 units Aut/Win/Spr";
//    meta[4] = "27+ EDUC units";
//    meta[5] = "95+ units toward MA/MPP";
//    meta[6] = "Course Requirements"; // how calculate?
//  } else if (program === "eds") {
//    meta[0] = "All courses at or above the 100 level";
//    meta[1] = "17+ Letter graded units";
//    meta[2] = "25+ units at or above the 200 level";
//    meta[3] = "8-18 units Aut/Win/Spr";
//    meta[4] = "30+ EDUC units";
//    meta[5] = "51+ units toward MS";
//    meta[6] = "Course Requirements"; // how calculate?
//  } else if (program === "ice") {
//    meta[0] = "All courses at or above the 100 level";
//    meta[1] = "16+ Letter graded units";
//    meta[2] = "24+ units at or above the 200 level";
//    meta[3] = "11/11/8/6 or 11/11/11/3";
//    meta[4] = "30+ EDUC units";
//    meta[5] = "48+ units toward MA";
//    meta[6] = "Course Requirements"; // how calculate?
//  } else if (program === "iepa") {
//    meta[0] = "All courses at or above the 100 level";
//    meta[1] = "16+ Letter graded units";
//    meta[2] = "24+ units at or above the 200 level";
//    meta[3] = "11/11/8/6 or 11/11/11/3";
//    meta[4] = "30+ EDUC units";
//    meta[5] = "48+ units toward MA";
//    meta[6] = "Course Requirements"; // how calculate?
//  } else if (program === "joint_ma_mba") {
//    meta[0] = "All courses at or above the 100 level";
//    meta[1] = "10 cross-listed units max";
//    meta[2] = "EDUC 393";
//    meta[3] = "18+ units at or above the 200 level";
//    meta[4] = "27+ Letter graded units";
//    meta[5] = "4 independent study units max";
//    meta[6] = "35+ units toward MA";
//  } else if (program === "designed") {
//    meta[0] = "All courses at or above the 100 level";
//    meta[1] = "15+ Letter graded units";
//    meta[2] = "23+ units at or above the 200 level";
//    meta[3] = "45+ units toward MA";
//    meta[4] = "27+ EDUC units";
//    meta[5] = "3.0 GPA"; // how calculate?
//  }
//  return meta;
//};
