import { Field } from "formik";

import { PlusIcon } from "@heroicons/react/24/outline";
import {
  insertRow,
  //programDifferentiatorHasCrossListed,
  programDifferentiatorHasProgramRequirement,
} from "../utils/auxiliary_functions";

//const program_tailwind_width_conditional = (program: string) =>
//  programDifferentiatorHasProgramRequirement(program) ||
//  programDifferentiatorHasCrossListed(program)
//    ? "w-5/12"
//    : "w-4/12";

export const AddCourse: React.FC<{
  rowIndex: number;
  courseIndex: number;
  values: any;
  setFieldValue: any;
  setInitialValuesLog: any;
}> = ({
  rowIndex,
  courseIndex,
  values,
  setFieldValue,
  setInitialValuesLog,
}) => {
  return (
    <>
      <div className="flex flex-wrap items-center mt-2 border-t pt-4 border-stone-400">
        <div className="flex flex-col w-3/12">
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#990000", // Cardinal red color
                marginBottom: "1rem",
                marginLeft: "0.6rem",
                marginTop: "0.6rem",
                cursor: "pointer", // Add cursor style to indicate it's clickable
                fontWeight: "bold",
              }}
              key={`add_${rowIndex}_courseIndex`}
              onClick={() => {
                let newRow: any = {
                  courseNumber: "",
                  courseTitle: "",
                  gradingOption: "",
                  courseUnits: 0,
                };
                if (
                  programDifferentiatorHasProgramRequirement(values.program)
                ) {
                  newRow["programRequirement"] = "";
                }
                insertRow(
                  rowIndex,
                  courseIndex,
                  newRow,
                  values,
                  setFieldValue,
                  setInitialValuesLog,
                );
              }}
            >
              <PlusIcon
                className="h-14 w-14 mr-5 font-bold"
                style={{ strokeWidth: "5" }}
              />
              Add Course
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const QuarterTotalUnits: React.FC<{
  rowIndex: number;
  row: any;
}> = ({ rowIndex, row }) => {
  return (
    <>
      <div className="flex flex-wrap items-center  font-bold border-t pt-4 pb-2 border-stone-400">
        {/*<div className="flex flex-col w-8/12 text-right"> */}
        {/*<div className="flex-grow text-center">*/}
        <div className="flex flex-col w-10/12 text-right pr-8">
          Quarter Units
        </div>

        <div className="flex flex-col w-1/12">
          <Field
            type="text"
            name={`rows[${rowIndex}].total`}
            className="input quarterTotalUnits border-none"
            value={row.total}
            disabled
          />
        </div>
      </div>
    </>
  );
};

//export const YearNumberTotalUnits: React.FC<{
//  rowIndex: number;
//  values: any;
//}> = ({ rowIndex, values }) => {
//  const tailwind_column_width = program_tailwind_width_conditional(
//    values.program,
//  );
//  return (
//    <>
//      <div className="flex flex-wrap items-center mt-2 pb-10">
//        <div className="flex flex-col w-1/12">
//          <div></div>
//        </div>
//        <div
//          className={`flex flex-col ${tailwind_column_width} ml-24 text-right`}
//        >
//          {"Year " + Math.floor((rowIndex + 1) / 3)} Total Units&nbsp;
//        </div>
//
//        <div className="flex flex-col w-1/12">
//          <Field
//            type="text"
//            name={"total_units_" + Math.floor((rowIndex + 1) / 3)}
//            className={`su-input total_units_${Math.floor((rowIndex + 1) / 3)}`}
//            disabled
//          />
//        </div>
//      </div>
//    </>
//  );
//};

export const AllTotalUnits: React.FC<{ totalSum: number }> = ({
  totalSum,
  //values,
}) => {
  //console.log(values);
  //const tailwind_column_width = program_tailwind_width_conditional(
  //  values.program,
  //);
  return (
    <>
      <div className="flex flex-wrap items-center mt-2 font-bold">
        <div className="flex flex-col w-8/12"></div>

        <div className="flex flex-col w-2/12 text-right border-l bg-stone-500 py-6 pr-15 text-white">
          Total Units
        </div>

        <div className="flex flex-col w-1/12 bg-stone-500 py-6 text-white">
          <div className="hidden">
            <Field
              type="text"
              name="total_units"
              className="input total_units border-none hidden"
              value={totalSum}
              disabled
            />
          </div>

          {totalSum}
        </div>
        <div className="flex flex-col w-1/12 bg-stone-500 py-6 text-white">
          &nbsp;
        </div>
      </div>
    </>
  );
};

{
  /*<div className="flex items-center space-x-2 my-2">
        <span className="font-bold">All Total Units:</span>
        <span className="text-xl">{totalSum}</span>
    </div> */
}
