// import { v4 as uuidv4 } from 'uuid';
import { createContext, useState, useEffect } from "react";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase.config.js";
import {
  getUsernameFromEmail,
  fetchAndPostFirebasePlusDefaults,
} from "../utils/auxiliary_functions";

interface UserData {
  email: string;
  username: string;
  displayName: string;
}

interface SessionContextType {
  userData: UserData;
  setUserData: (userData: UserData) => void;
}

export const SessionContext = createContext<SessionContextType>({
  userData: {
    email: "",
    username: "",
    displayName: "",
  },
  setUserData: () => {},
});

interface SessionProviderProps {
  children: any;
}

export function SessionProvider({ children }: SessionProviderProps) {
  const [userData, setUserData] = useState<UserData>({
    email: "",
    username: "",
    displayName: "",
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Grab the authenticated user from firebase auth
        const tempUserData = {
          email: user.email ?? "",
          username: getUsernameFromEmail(user.email as string) ?? "",
          displayName: user.displayName ?? "",
        };
        //setUserData(user.email ?? ""); // Update user in session context
        setUserData(tempUserData); // Update user in session context
        fetchAndPostFirebasePlusDefaults(tempUserData);
      }
    });
    return unsubscribe;
  }, []);

  const contextValue: SessionContextType = {
    userData,
    setUserData,
  };

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
}
